/**
 * OpenAPI common
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type UserHeightCentimeter = 140 | 145 | 150 | 155 | 160 | 165 | 170 | 175 | 180 | 185 | 190 | 195 | 200;

export const UserHeightCentimeter = {
    NUMBER_140: 140 as UserHeightCentimeter,
    NUMBER_145: 145 as UserHeightCentimeter,
    NUMBER_150: 150 as UserHeightCentimeter,
    NUMBER_155: 155 as UserHeightCentimeter,
    NUMBER_160: 160 as UserHeightCentimeter,
    NUMBER_165: 165 as UserHeightCentimeter,
    NUMBER_170: 170 as UserHeightCentimeter,
    NUMBER_175: 175 as UserHeightCentimeter,
    NUMBER_180: 180 as UserHeightCentimeter,
    NUMBER_185: 185 as UserHeightCentimeter,
    NUMBER_190: 190 as UserHeightCentimeter,
    NUMBER_195: 195 as UserHeightCentimeter,
    NUMBER_200: 200 as UserHeightCentimeter
};

