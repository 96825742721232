/**
 * OpenAPI common
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type InquiryCategory = 'CanNotLogin' | 'DisconnectionOnLogin' | 'ErrorOnLogin' | 'Withdrawal' | 'Registration' | 'Pricing' | 'PurchasedDespiteWithdrawal' | 'OtherPricingIssues' | 'PreviousRegistration' | 'Reregistration' | 'GotIntoTrouble' | 'bankTransfer' | 'NotReflected' | 'DifferentAccount' | 'BankTransferQuestion' | 'OtherPayment' | 'NotReflectedPayment' | 'AutoRenewal' | 'PaymentQuestion' | 'SystemDefects' | 'ImproperUser' | 'SuspiciousPerson' | 'Certificate' | 'IdentityVerification' | 'IdentityNotVerification' | 'IncomeCertificate' | 'IncomeCertificateApproval' | 'Features' | 'Feedback' | 'Other' | 'BusinessInquiry';

export const InquiryCategory = {
    CanNotLogin: 'CanNotLogin' as InquiryCategory,
    DisconnectionOnLogin: 'DisconnectionOnLogin' as InquiryCategory,
    ErrorOnLogin: 'ErrorOnLogin' as InquiryCategory,
    Withdrawal: 'Withdrawal' as InquiryCategory,
    Registration: 'Registration' as InquiryCategory,
    Pricing: 'Pricing' as InquiryCategory,
    PurchasedDespiteWithdrawal: 'PurchasedDespiteWithdrawal' as InquiryCategory,
    OtherPricingIssues: 'OtherPricingIssues' as InquiryCategory,
    PreviousRegistration: 'PreviousRegistration' as InquiryCategory,
    Reregistration: 'Reregistration' as InquiryCategory,
    GotIntoTrouble: 'GotIntoTrouble' as InquiryCategory,
    BankTransfer: 'bankTransfer' as InquiryCategory,
    NotReflected: 'NotReflected' as InquiryCategory,
    DifferentAccount: 'DifferentAccount' as InquiryCategory,
    BankTransferQuestion: 'BankTransferQuestion' as InquiryCategory,
    OtherPayment: 'OtherPayment' as InquiryCategory,
    NotReflectedPayment: 'NotReflectedPayment' as InquiryCategory,
    AutoRenewal: 'AutoRenewal' as InquiryCategory,
    PaymentQuestion: 'PaymentQuestion' as InquiryCategory,
    SystemDefects: 'SystemDefects' as InquiryCategory,
    ImproperUser: 'ImproperUser' as InquiryCategory,
    SuspiciousPerson: 'SuspiciousPerson' as InquiryCategory,
    Certificate: 'Certificate' as InquiryCategory,
    IdentityVerification: 'IdentityVerification' as InquiryCategory,
    IdentityNotVerification: 'IdentityNotVerification' as InquiryCategory,
    IncomeCertificate: 'IncomeCertificate' as InquiryCategory,
    IncomeCertificateApproval: 'IncomeCertificateApproval' as InquiryCategory,
    Features: 'Features' as InquiryCategory,
    Feedback: 'Feedback' as InquiryCategory,
    Other: 'Other' as InquiryCategory,
    BusinessInquiry: 'BusinessInquiry' as InquiryCategory
};

