/**
 * OpenAPI common
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type PurchaseSubscriptionItemName = 'MensPremiumSubscription1Month' | 'MensPremiumSubscription3Months' | 'MensPremiumSubscription6Months' | 'MensPremiumSubscription12Months';

export const PurchaseSubscriptionItemName = {
    MensPremiumSubscription1Month: 'MensPremiumSubscription1Month' as PurchaseSubscriptionItemName,
    MensPremiumSubscription3Months: 'MensPremiumSubscription3Months' as PurchaseSubscriptionItemName,
    MensPremiumSubscription6Months: 'MensPremiumSubscription6Months' as PurchaseSubscriptionItemName,
    MensPremiumSubscription12Months: 'MensPremiumSubscription12Months' as PurchaseSubscriptionItemName
};

