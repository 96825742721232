import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import { Amplify } from 'aws-amplify';

declare const twq: Function;

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));

Amplify.configure(environment.amplify);

gtag('js', new Date());
gtag('config', environment.analysis?.gtagId);
fbq('init', environment.analysis?.pixelId);
twq('init', environment.analysis?.uwtId);
