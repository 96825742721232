/**
 * OpenAPI common
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ChatMessageReactionType = 'SalutingFace' | 'FaceRaisingHands' | 'BowingFace' | 'FaceWithFoldedHands' | 'ThumbsUp' | 'Diamond' | 'Heart' | 'Eyes';

export const ChatMessageReactionType = {
    SalutingFace: 'SalutingFace' as ChatMessageReactionType,
    FaceRaisingHands: 'FaceRaisingHands' as ChatMessageReactionType,
    BowingFace: 'BowingFace' as ChatMessageReactionType,
    FaceWithFoldedHands: 'FaceWithFoldedHands' as ChatMessageReactionType,
    ThumbsUp: 'ThumbsUp' as ChatMessageReactionType,
    Diamond: 'Diamond' as ChatMessageReactionType,
    Heart: 'Heart' as ChatMessageReactionType,
    Eyes: 'Eyes' as ChatMessageReactionType
};

