/**
 * OpenAPI common
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ChatTheme = 'Default' | 'Monotone' | 'Green' | 'Blue' | 'Thread';

export const ChatTheme = {
    Default: 'Default' as ChatTheme,
    Monotone: 'Monotone' as ChatTheme,
    Green: 'Green' as ChatTheme,
    Blue: 'Blue' as ChatTheme,
    Thread: 'Thread' as ChatTheme
};

