/**
 * OpenAPI common
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ChatStatus = 'Sent' | 'Deleted' | 'NotDelivered';

export const ChatStatus = {
    Sent: 'Sent' as ChatStatus,
    Deleted: 'Deleted' as ChatStatus,
    NotDelivered: 'NotDelivered' as ChatStatus
};

