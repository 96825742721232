/**
 * OpenAPI common
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type PurchaseProductName = 'MensPremiumSubscription1Month' | 'MensPremiumSubscription3Months' | 'MensPremiumSubscription6Months' | 'MensPremiumSubscription12Months' | 'MensDiamondSubscriptionAddOnAppend' | 'MensDiamondAddOnIncludedPremiumSubscription1Month' | 'MensDiamondAddOnIncludedPremiumSubscription3Months' | 'MensDiamondAddOnIncludedPremiumSubscription6Months' | 'MensDiamondAddOnIncludedPremiumSubscription12Months';

export const PurchaseProductName = {
    MensPremiumSubscription1Month: 'MensPremiumSubscription1Month' as PurchaseProductName,
    MensPremiumSubscription3Months: 'MensPremiumSubscription3Months' as PurchaseProductName,
    MensPremiumSubscription6Months: 'MensPremiumSubscription6Months' as PurchaseProductName,
    MensPremiumSubscription12Months: 'MensPremiumSubscription12Months' as PurchaseProductName,
    MensDiamondSubscriptionAddOnAppend: 'MensDiamondSubscriptionAddOnAppend' as PurchaseProductName,
    MensDiamondAddOnIncludedPremiumSubscription1Month: 'MensDiamondAddOnIncludedPremiumSubscription1Month' as PurchaseProductName,
    MensDiamondAddOnIncludedPremiumSubscription3Months: 'MensDiamondAddOnIncludedPremiumSubscription3Months' as PurchaseProductName,
    MensDiamondAddOnIncludedPremiumSubscription6Months: 'MensDiamondAddOnIncludedPremiumSubscription6Months' as PurchaseProductName,
    MensDiamondAddOnIncludedPremiumSubscription12Months: 'MensDiamondAddOnIncludedPremiumSubscription12Months' as PurchaseProductName
};

