/**
 * OpenAPI common
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type InquiryCategoryGroup = 'AuthenticatedUsers' | 'UnauthenticatedUsers' | 'CurrentUsers' | 'UsersWhoUsedToUse' | 'UsersWhoHaveNeverUsed' | 'ManUsers' | 'WomanUsers';

export const InquiryCategoryGroup = {
    AuthenticatedUsers: 'AuthenticatedUsers' as InquiryCategoryGroup,
    UnauthenticatedUsers: 'UnauthenticatedUsers' as InquiryCategoryGroup,
    CurrentUsers: 'CurrentUsers' as InquiryCategoryGroup,
    UsersWhoUsedToUse: 'UsersWhoUsedToUse' as InquiryCategoryGroup,
    UsersWhoHaveNeverUsed: 'UsersWhoHaveNeverUsed' as InquiryCategoryGroup,
    ManUsers: 'ManUsers' as InquiryCategoryGroup,
    WomanUsers: 'WomanUsers' as InquiryCategoryGroup
};

