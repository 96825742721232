import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, ActivatedRouteSnapshot, Data, Navigation, NavigationEnd, Router, Scroll } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';
import { ScrollRestorationService } from './shared/router/scroll-restoration.service';
import { environment } from 'src/environments/environment';

declare const gtag: Function;
declare const fbq: Function;
declare const twq: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  defaultTitle: string = 'SugarDaddy(シュガーダディ) - ワンランク上のマッチングアプリ';
  defaultDescription: string =
    '「魅力的な女性」と「成功した男性」の出会いを応援するマッチングアプリです。';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private scrollRestorationService: ScrollRestorationService,
    private titleService: Title,
    private metaService: Meta,
  ) {
    this.metaService.addTag({ name: 'robots', content: 'index,follow' });
    if (environment.site.noindex == 'yes') {
      this.metaService.updateTag({ name: 'robots', content: 'noindex,nofollow,noarchive' });
    }

    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        gtag('set', 'page_path', event.url);
        gtag('event', 'page_view');
        fbq('track', 'PageView');
        twq('track', 'PageView');
      }
    });

    this.scrollRestorationService.init().subscribe();
  }

  ngOnInit(): void {
    this.updateMetaTagInfo();
  }

  updateMetaTagInfo() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => getMergedRouteData(this.route.snapshot)),
        map((data) => {
          console.log('AppComponent.updateMetaTagInfo():', data);
          if (data.title) {
            this.titleService.setTitle(data.title + (data.titleSuffix || ''));
          } else if (data.header?.text) {
            this.titleService.setTitle(data.header.text);
          } else if (data.header?.imgAlt) {
            this.titleService.setTitle('SugarDaddy');
          } else {
            this.titleService.setTitle(this.defaultTitle);
          }

          if (data.description) {
            this.metaService.updateTag({ name: 'description', content: data.description });
          } else {
            this.metaService.updateTag({ name: 'description', content: this.defaultDescription });
          }

          if (data.robots) {
            this.metaService.updateTag({ name: 'robots', content: data.robots });
          } else {
            this.metaService.removeTag("name='robots'");
          }

          if (environment.site.noindex != 'yes') {
            if (data.robots) {
              this.metaService.updateTag({ name: 'robots', content: data.robots });
            } else {
              this.metaService.removeTag("name='robots'");
            }
          }

          const canonical: HTMLLinkElement = document.head.querySelector('link[rel="canonical"]');

          if (data.canonicalUrl) {
            if (canonical) {
              canonical.href = data.canonicalUrl;
            } else {
              const link: HTMLLinkElement = document.createElement('link');
              link.rel = 'canonical';
              link.href = data.canonicalUrl;
              document.head.appendChild(link);
            }
          } else {
            if (canonical) {
              document.head.removeChild(canonical);
            }
          }
        })
      ).subscribe();
  }
}

function getMergedRouteData(route: ActivatedRouteSnapshot): Data {
  return [].concat(...route.children.map(child => getMergedRouteData(child))).reduce((a, b) => Object.assign(a, b), Object.assign({}, route.data));
}
