/**
 * OpenAPI common
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type SystemreportsvcSystemReportType = 'APPLICATION_INFO' | 'API_ERROR' | 'APPLICATION_ERROR' | 'UNKNOWN_ERROR';

export const SystemreportsvcSystemReportType = {
    ApplicationInfo: 'APPLICATION_INFO' as SystemreportsvcSystemReportType,
    ApiError: 'API_ERROR' as SystemreportsvcSystemReportType,
    ApplicationError: 'APPLICATION_ERROR' as SystemreportsvcSystemReportType,
    UnknownError: 'UNKNOWN_ERROR' as SystemreportsvcSystemReportType
};

