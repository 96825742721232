/**
 * OpenAPI common
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type LotteryLotteryEntryStatus = 'Entered' | 'Won' | 'Canceled';

export const LotteryLotteryEntryStatus = {
    Entered: 'Entered' as LotteryLotteryEntryStatus,
    Won: 'Won' as LotteryLotteryEntryStatus,
    Canceled: 'Canceled' as LotteryLotteryEntryStatus
};

