import { HttpErrorResponse } from "@angular/common/http";
import { ErrorHandler, Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { catchError } from "rxjs/operators";
import { SystemReportApiService } from "./api/api/systemReport.service";
import { AuthService } from "./auth/auth.service";
import { UAParser } from 'ua-parser-js';
import { HandleErrorRequest } from "./api/model/handleErrorRequest";
import { SystemreportsvcSystemReportType } from "./api/model/systemreportsvcSystemReportType";

@Injectable()
export class AppErrorHandler implements ErrorHandler {

    private parser: UAParser = new UAParser();

    constructor(
        private authService: AuthService,
        private systemReportApiService: SystemReportApiService,
    ) { }

    handleError(error: any): void {
        const req: HandleErrorRequest = {
            userId: this.authService.userId,
            ua: JSON.stringify(this.parser.getResult()),
            time: (new Date()).toISOString(),
        };

        if (error instanceof HttpErrorResponse) {
            console.log('[AppErrorHandler] HttpErrorResponse:', error);
            req.type = SystemreportsvcSystemReportType.ApiError;
            req.executionId = error?.headers?.get('x-execution-id');
            req.statusCode = error?.status;
            req.response = JSON.stringify(error)

        } else if (error instanceof Error) {
            console.log('[AppErrorHandler] error:', error);
            req.type = SystemreportsvcSystemReportType.ApplicationError;
            req.error = error?.name;
            req.stack = `${error?.stack}`;

        } else {
            console.log('[AppErrorHandler] unknown error:', error);
            req.type = SystemreportsvcSystemReportType.UnknownError;
            req.error = error;
        }

        // this.systemReportApiService.handleError(req).pipe(catchError((err: any) => of(true))).subscribe();

        // TODO: ignore error on production
        // throw error;
    }
}
