/**
 * OpenAPI common
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type UserSmokingAmount = 'Not' | 'Sometimes' | 'Usually';

export const UserSmokingAmount = {
    Not: 'Not' as UserSmokingAmount,
    Sometimes: 'Sometimes' as UserSmokingAmount,
    Usually: 'Usually' as UserSmokingAmount
};

