import { Injectable } from '@angular/core';
import { UAParser } from 'ua-parser-js';
import { v4 as uuid } from 'uuid';

@Injectable({
  providedIn: 'root'
})
export class UaService {

  private parser: UAParser = new UAParser();

  constructor() { }

  get ua(): string { return this.parser.getUA(); }

  get device(): DeviceType {
    const deviceType = this.parser.getDevice()?.type;
    if (deviceType == 'mobile') {
      return DeviceType.Mobile;
    } else if (deviceType == 'tablet') {
      return DeviceType.Tablet;
    } else {
      return DeviceType.Desktop;
    }
  }

  get isIos(): boolean {
    const deviceName = this.parser.getOS()?.name;
    return deviceName == 'iOS';
  }

  get isAndroid(): boolean {
    const deviceName = this.parser.getOS()?.name;
    return deviceName == 'Android';
  }

  get isMobile(): boolean { return this.device === DeviceType.Mobile; }
  get isTablet(): boolean { return this.device === DeviceType.Tablet; }
  get isDesktop(): boolean { return this.device === DeviceType.Desktop; }

  get uaId(): string {
    let uaId = localStorage.getItem('ua_id');
    if (!uaId) {
      uaId = uuid();
      localStorage.setItem('ua_id', uaId);
    }
    return uaId;
  }
}

export enum DeviceType {
  Mobile = 'mobile',
  Tablet = 'tablet',
  Desktop = 'desktop',
}
