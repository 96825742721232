/**
 * OpenAPI common
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type UserimageApplicability = 'Indefinite' | 'Pending' | 'Indeterminate' | 'Deffered' | 'Applicable' | 'Inapplicable';

export const UserimageApplicability = {
    Indefinite: 'Indefinite' as UserimageApplicability,
    Pending: 'Pending' as UserimageApplicability,
    Indeterminate: 'Indeterminate' as UserimageApplicability,
    Deffered: 'Deffered' as UserimageApplicability,
    Applicable: 'Applicable' as UserimageApplicability,
    Inapplicable: 'Inapplicable' as UserimageApplicability
};

