/**
 * OpenAPI common
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type UserStatus = 'New' | 'Active' | 'Suspended' | 'Withdrawed' | 'WithdrawedByAdmin' | 'Frozen' | 'Archived';

export const UserStatus = {
    New: 'New' as UserStatus,
    Active: 'Active' as UserStatus,
    Suspended: 'Suspended' as UserStatus,
    Withdrawed: 'Withdrawed' as UserStatus,
    WithdrawedByAdmin: 'WithdrawedByAdmin' as UserStatus,
    Frozen: 'Frozen' as UserStatus,
    Archived: 'Archived' as UserStatus
};

