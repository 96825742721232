import { Injectable } from '@angular/core';
import { Subject, Observable, of, Subscription, interval, throwError, BehaviorSubject } from 'rxjs';
import { filter, map, mergeMap, catchError } from 'rxjs/operators';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { AuthService } from '../../../auth/auth.service';
import { EventNotification } from '../../../api';

@Injectable({
  providedIn: 'root'
})
export class EventNotificationService {
  ws: WebSocketSubject<EventNotification>;
  events: Subject<EventNotification> = new Subject();
  status: BehaviorSubject<EventNotificationState> = new BehaviorSubject<EventNotificationState>({ connected: false });

  get connected(): boolean { return !!this.status.getValue()?.connected; }
  get pending(): boolean { return !!this.status.getValue()?.pending; }
  get closeAbnormality(): boolean { return !!this.status.getValue()?.closeAbnormality; }

  sub: Subscription = new Subscription();

  constructor(
    private config: EventNotificationConfig,
    private authService: AuthService,
  ) { }

  start() {
    console.log('EventNotificationService.start():');
    this.sub.unsubscribe();
    this.sub = new Subscription();
    this.sub.add(this.connect().subscribe());
  }

  connect(): Observable<EventNotification> {
    console.log('EventNotificationService.connect():');
    this.status.next({ connected: false, pending: true })

    const bypassCache = this.authService.accessTokenExpired;

    return this.authService.currentUser(bypassCache).pipe(
      mergeMap((user) => {
        console.log('EventNotificationService.connect():', user);

        const url: URL = new URL(this.config.endpoint);
        url.pathname = '/social/v1/event_notification/receive';

        const bearer = btoa('Bearer ' + this.authService.accessToken).replace(/=/g, '');

        this.ws = webSocket({
          url: url.toString(),
          protocol: bearer,
          openObserver: {
            next: x => {
              console.log('EventNotificationService.connect.ws(): openObserver got a next value: ', x);
              this.status.next({ connected: true });
            },
            error: err => console.error('EventNotificationService.connect.ws(): openObserver got an error: ', err),
            complete: () => console.log('EventNotificationService.connect.ws(): openObserver got a complete notification'),
          },
          closeObserver: {
            next: x => {
              console.log('EventNotificationService.connect.ws(): closeObserver got a next value: ', x);
              this.status.next({ connected: false, closeAbnormality: !x.wasClean });
            },
            error: err => console.error('EventNotificationService.connect.ws(): closeObserver got an error: ', err),
            complete: () => console.log('EventNotificationService.connect.ws(): closeObserver got a complete notification'),
          },
          closingObserver: {
            next: x => console.log('EventNotificationService.connect.ws(): closingObserver got a next value: ', x),
            error: err => console.error('EventNotificationService.connect.ws(): closingObserver got an error: ', err),
            complete: () => console.log('EventNotificationService.connect.ws(): closingObserver got a complete notification'),
          },
        });

        return this.ws.pipe(
          map((entity: EventNotification) => {
            console.log('EventNotificationService.connect.ws.map():', entity);
            this.events.next(entity);
            return entity;
          })
        );
      }),
      catchError((err: any) => {
        this.status.next({ connected: false });
        return throwError(err);
      })
    );
  }

  disconnect() {
    this.sub.unsubscribe();
    this.sub = new Subscription();
  }

  receive<T>(eventName: string | string[]): Observable<T> {
    console.log('EventNotificationService.receive(): event name:', eventName);
    if (!Array.isArray(eventName)) {
      eventName = [eventName];
    }
    return this.events.pipe(
      filter((entity: EventNotification) => {
        return entity && eventName.includes(entity?.name);
      }),
      map((entity: EventNotification) => {
        return entity.payload as unknown as T;
      }),
      catchError(err => {
        console.log('EventNotificationService.receive.catchError():', eventName, err);
        return of(err);
      })
    );
  }
}

export class EventNotificationConfig {
  endpoint: string;
  retryInterval: number;
  retryCount: number;
}

export interface EventNotificationState {
  connected: boolean;
  pending?: boolean;
  closeAbnormality?: boolean;
}
