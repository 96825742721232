import { ViewportScroller } from '@angular/common';
import { EventEmitter, Injectable } from '@angular/core';
import { Router, Navigation, NavigationEnd, Scroll } from '@angular/router';
import { combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ScrollRestorationService {
  public onScrollEvent: EventEmitter<ScrollRestorationEvent> = new EventEmitter();

  constructor(
    private router: Router,
    private viewportScroller: ViewportScroller,
  ) { }

  // Custom scroll position restoration
  // See https://github.com/angular/angular/blob/1ba551b888ad4502151df3f7113ce651f58e83c3/packages/router/src/router_scroller.ts#L73
  init(): Observable<void> {
    this.viewportScroller.setHistoryScrollRestoration('manual');

    return combineLatest([
      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd),
        map(event => ({ event, navigation: this.router.getCurrentNavigation() }))
      ),
      this.router.events.pipe(
        filter(event => event instanceof Scroll)
      ),
    ]).pipe(
      map(events => {
        const navigation = events[0]?.navigation as Navigation;
        const navigationEndEvent = events[0]?.event as NavigationEnd;
        const scrollEvent = events[1] as Scroll;
        const event = { navigation, navigationEndEvent, scrollEvent } as ScrollRestorationEvent;
        console.log('ScrollRestorationService.init(): events', navigation, navigationEndEvent, scrollEvent);

        if (navigationEndEvent.id !== scrollEvent.routerEvent.id) {
          console.log('ScrollRestorationService.init(): skip: different id', navigationEndEvent.id, scrollEvent.routerEvent.id);
          return;

        } else if (navigation.trigger === 'imperative' && !!navigation.extras?.state?.keepScrollPosition) {
          console.log('ScrollRestorationService.init(): skip: keepScrollPosition enabled');
          event.name = ScrollRestorationEventName.KeepScrollPosition;

        } else if (scrollEvent.position) {
          console.log('ScrollRestorationService.init(): restore scroll position', scrollEvent.position);
          this.viewportScroller.scrollToPosition(scrollEvent.position);
          event.name = ScrollRestorationEventName.ScrollRestore;

        } else if (!scrollEvent.anchor) {
          console.log('ScrollRestorationService.init(): reset scroll position');
          this.viewportScroller.scrollToPosition([0, -60]);
          event.name = ScrollRestorationEventName.ScrollTop;
        }

        this.onScrollEvent.emit(event);
      })
    );
  }
}

export interface ScrollRestorationEvent {
  name: ScrollRestorationEventName;
  navigation: Navigation;
  navigationEndEvent: NavigationEnd;
  scrollEvent: Scroll;
}

export enum ScrollRestorationEventName {
  ScrollTop,
  ScrollRestore,
  KeepScrollPosition,
}
