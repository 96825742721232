import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from './layout/header/header.component';
import { FooterComponent } from './layout/footer/footer.component';
import { CompanyProfileDocumentComponent } from './document/company-profile-document/company-profile-document.component';
import { RulePageDocumentComponent } from './document/rule-page-document/rule-page-document.component';
import { RestrictionPageDocumentComponent } from './document/restriction-page-document/restriction-page-document.component';
import { LawPageDocumentComponent } from './document/law-page-document/law-page-document.component';
import { PrivacyPolicyPageDocumentComponent } from './document/privacy-policy-page-document/privacy-policy-page-document.component';
import { SectionTitleComponent } from './layout/section-title/section-title.component';
import { IsMobilePipe } from './ua/is-mobile.pipe';
import { IsTabletPipe } from './ua/is-tablet.pipe';
import { IsDesktopPipe } from './ua/is-desktop.pipe';
import { FormatAttrPipe } from './attr/format-attr.pipe';
import { FormErrorComponent } from './form/form-error/form-error.component';
import { RecommendedEnviromentPageDocumentComponent } from './document/recommended-enviroment-page-document/recommended-enviroment-page-document.component';
import { LoadingComponent } from './layout/loading/loading.component';
import { ColumnDateParsePipe } from './wp/pipe/column-date-parse.pipe';
import { AccordionComponent } from './layout/accordion/accordion.component';
import { LinkPanelComponent } from './layout/link-panel/link-panel.component';
import { LoadingOverlayComponent } from './loading/loading-overlay/loading-overlay.component';

@NgModule({
  declarations: [
    RulePageDocumentComponent,
    RestrictionPageDocumentComponent,
    LawPageDocumentComponent,
    PrivacyPolicyPageDocumentComponent,
    FooterComponent,
    HeaderComponent,
    CompanyProfileDocumentComponent,
    SectionTitleComponent,
    IsMobilePipe,
    IsTabletPipe,
    IsDesktopPipe,
    FormatAttrPipe,
    FormErrorComponent,
    RecommendedEnviromentPageDocumentComponent,
    LoadingComponent,
    ColumnDateParsePipe,
    AccordionComponent,
    LinkPanelComponent,
    LoadingOverlayComponent,
  ],
  imports: [CommonModule, RouterModule],
  exports: [
    RulePageDocumentComponent,
    RestrictionPageDocumentComponent,
    LawPageDocumentComponent,
    PrivacyPolicyPageDocumentComponent,
    FooterComponent,
    HeaderComponent,
    CompanyProfileDocumentComponent,
    RecommendedEnviromentPageDocumentComponent,
    SectionTitleComponent,
    IsMobilePipe,
    IsTabletPipe,
    IsDesktopPipe,
    FormatAttrPipe,
    FormErrorComponent,
    LoadingComponent,
    ColumnDateParsePipe,
    AccordionComponent,
    LinkPanelComponent,
    LoadingOverlayComponent,
  ],
})
export class SharedModule { }
