import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { AuthService } from './auth.service';
import { catchError, mergeMap } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class AuthTokenInterceptor implements HttpInterceptor {

  constructor(
    private router: Router,
    private service: AuthService,
  ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const authKey = request.headers.keys().find(k => request.headers.get(k) == 'Bearer COGNITO_ACCESS_TOKEN');
    const bypassCache = this.service.accessTokenExpired;
    console.log('[AuthTokenInterceptor] request: ', request, authKey, bypassCache);
    if (authKey) {
      return this.service.currentUser(bypassCache).pipe(
        catchError((err: any) => {
          console.log('[AuthTokenInterceptor] catch error: ', err);
          this.router.navigate(['/login']);
          return throwError(err);
        }),
        mergeMap((user) => {
          console.log('[AuthTokenInterceptor] current user: ', user);
          const accessToken = this.service.getUserAccessToken(user);
          const nextRequest = request.clone({ setHeaders: { [authKey]: 'Bearer ' + accessToken } });
          return next.handle(nextRequest);
        })
      );
    }
    return next.handle(request);
  }
}

export const TOKEN_PLACEHOLDER = 'COGNITO_ACCESS_TOKEN';
