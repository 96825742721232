/**
 * OpenAPI common
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type PurchaseProductGroup = 'MensPremiumSubscription' | 'MensDiamondAddOnIncludedPremiumSubscription' | 'MensDiamondSubscriptionAddOnAppend' | 'SubscriptionBalancing';

export const PurchaseProductGroup = {
    MensPremiumSubscription: 'MensPremiumSubscription' as PurchaseProductGroup,
    MensDiamondAddOnIncludedPremiumSubscription: 'MensDiamondAddOnIncludedPremiumSubscription' as PurchaseProductGroup,
    MensDiamondSubscriptionAddOnAppend: 'MensDiamondSubscriptionAddOnAppend' as PurchaseProductGroup,
    SubscriptionBalancing: 'SubscriptionBalancing' as PurchaseProductGroup
};

