/**
 * OpenAPI common
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type PurchasePaymentMethod = 'Manual' | 'TelecomCredit' | 'Bitcash' | 'Paidy' | 'BankTransfer';

export const PurchasePaymentMethod = {
    Manual: 'Manual' as PurchasePaymentMethod,
    TelecomCredit: 'TelecomCredit' as PurchasePaymentMethod,
    Bitcash: 'Bitcash' as PurchasePaymentMethod,
    Paidy: 'Paidy' as PurchasePaymentMethod,
    BankTransfer: 'BankTransfer' as PurchasePaymentMethod
};

