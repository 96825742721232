/**
 * OpenAPI common
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type UserActivityControl = 'AtMost15Minutes' | 'AtMost30Minutes' | 'AtMost1Hours' | 'AtMost3Hours' | 'AtMost6Hours' | 'AtMost24Hours' | 'AtMost2Days' | 'AtMost3Days' | '3DaysOrMore';

export const UserActivityControl = {
    AtMost15Minutes: 'AtMost15Minutes' as UserActivityControl,
    AtMost30Minutes: 'AtMost30Minutes' as UserActivityControl,
    AtMost1Hours: 'AtMost1Hours' as UserActivityControl,
    AtMost3Hours: 'AtMost3Hours' as UserActivityControl,
    AtMost6Hours: 'AtMost6Hours' as UserActivityControl,
    AtMost24Hours: 'AtMost24Hours' as UserActivityControl,
    AtMost2Days: 'AtMost2Days' as UserActivityControl,
    AtMost3Days: 'AtMost3Days' as UserActivityControl,
    _3DaysOrMore: '3DaysOrMore' as UserActivityControl
};

