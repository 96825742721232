import { Injectable } from '@angular/core';
import { Auth } from 'aws-amplify';
import { CognitoUser, CognitoUserSession, CognitoIdToken } from 'amazon-cognito-identity-js';
import { Observable, of, from, BehaviorSubject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private userSubject: BehaviorSubject<CognitoUser> = new BehaviorSubject<CognitoUser>(null);
  user$: Observable<CognitoUser> = this.userSubject.asObservable();

  get user(): CognitoUser { return this.userSubject.getValue(); }
  set user(user: CognitoUser) {
    this.userSubject.next(user);
  };

  constructor() { }

  currentUser(bypassCache: boolean = false): Observable<CognitoUser> {
    return from(Auth.currentUserPoolUser({ bypassCache })).pipe(
      map((user: CognitoUser) => {
        const changed = JSON.stringify(user) != JSON.stringify(this.user);
        console.log('AuthService.currentUser():', user, changed);
        if (changed) this.user = user;
        return user;
      })
    );
  }

  currentSession(): Observable<CognitoUserSession> {
    return from(Auth.currentSession()).pipe(
      map((sess: CognitoUserSession) => {
        console.log('AuthService.currentSession():', sess);
        return sess;
      })
    );
  }

  isAuthorized(bypassCache: boolean = false): Observable<boolean> {
    return this.currentUser(bypassCache).pipe(
      map((user: CognitoUser) => {
        const sess: CognitoUserSession | null = user.getSignInUserSession();
        var result: boolean = false;
        if (sess) {
          const token: CognitoIdToken = sess.getIdToken();
          const authFactorVerified = token.payload.phone_number_verified || token.payload.email_verified;
          result = sess.isValid() && authFactorVerified;
        }
        console.log('  user:', user);
        console.log('  result:', result);
        return result;
      }),
      catchError(() => of(false))
    );
  }

  signOut(): Observable<any> {
    return from(Auth.signOut()).pipe(
      map(res => {
        this.user = null;
        return res;
      })
    );
  }

  hasCache(): boolean {
    return !!Array.from(Array(localStorage.length).keys())
      .map(i => localStorage.key(i))
      .find(k => k.startsWith("CognitoIdentityServiceProvider"));
  }

  clearCache() {
    Array.from(Array(localStorage.length).keys())
      .map(i => localStorage.key(i))
      .filter(k => k.startsWith("CognitoIdentityServiceProvider"))
      .forEach(k => localStorage.removeItem(k));
    this.user = null;
  }

  get deviceKey$(): Observable<string> {
    return this.currentUser().pipe(
      map(user => user?.getSignInUserSession()?.getAccessToken()?.payload?.device_key)
    );
  }

  get userId(): string {
    return (this.user?.getSignInUserSession()?.getIdToken()?.payload || {})['cognito:username'];
  }

  get phoneNumber(): string {
    return (this.user as any)?.attributes?.phone_number;
  }

  get email(): string {
    return (this.user as any)?.attributes?.email;
  }

  get hasPhoneNumber(): boolean {
    return !!(this.user as any)?.attributes?.phone_number;
  }

  get hasEmail(): boolean {
    return !!(this.user as any)?.attributes?.email;
  }

  getUserAccessToken(user: any): string {
    const sess = user.getSignInUserSession();
    return sess ? sess.getAccessToken().getJwtToken() : '';
  }

  getUserIdToken(user: any): string {
    const sess = user.getSignInUserSession();
    return sess ? sess.getIdToken().getJwtToken() : '';
  }

  get accessToken(): string {
    return this.getUserAccessToken(this.user);
  }

  get idToken(): string {
    return this.getUserIdToken(this.user);
  }

  get accessTokenExpired(): boolean {
    const exp = this.user?.getSignInUserSession()?.getAccessToken()?.payload?.exp;
    return !(new Date(exp * 1000) > new Date());
  }
}
