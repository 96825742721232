/**
 * OpenAPI common
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type UserActivityLevel = 'Now' | 'Within15Minutes' | 'Within30Minutes' | 'Within1Hour' | 'Within3Hours' | 'Within6Hours' | 'Within1Day' | 'Within2Days' | 'Within3Days' | '3DaysOrMore';

export const UserActivityLevel = {
    Now: 'Now' as UserActivityLevel,
    Within15Minutes: 'Within15Minutes' as UserActivityLevel,
    Within30Minutes: 'Within30Minutes' as UserActivityLevel,
    Within1Hour: 'Within1Hour' as UserActivityLevel,
    Within3Hours: 'Within3Hours' as UserActivityLevel,
    Within6Hours: 'Within6Hours' as UserActivityLevel,
    Within1Day: 'Within1Day' as UserActivityLevel,
    Within2Days: 'Within2Days' as UserActivityLevel,
    Within3Days: 'Within3Days' as UserActivityLevel,
    _3DaysOrMore: '3DaysOrMore' as UserActivityLevel
};

