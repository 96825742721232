/**
 * OpenAPI common
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type AnnouncementAnnouncementName = 'AdHoc' | 'CertificateApproval_AgeVerificationSuccessed' | 'CertificateApproval_IdentificationApproved' | 'CertificateApproval_IdentificationRejected' | 'CertificateApproval_IncomeCertificateApproved' | 'CertificateApproval_IncomeCertificateRejected' | 'UserImageApproval_ProfilePictureApproved' | 'UserImageApproval_ProfilePictureRejected' | 'UserImage_Deleted' | 'UserProfile_Moderated' | 'UserPost_Banned' | 'ReportingAction_Warning' | 'IncomeCertificateRenewal';

export const AnnouncementAnnouncementName = {
    AdHoc: 'AdHoc' as AnnouncementAnnouncementName,
    CertificateApprovalAgeVerificationSuccessed: 'CertificateApproval_AgeVerificationSuccessed' as AnnouncementAnnouncementName,
    CertificateApprovalIdentificationApproved: 'CertificateApproval_IdentificationApproved' as AnnouncementAnnouncementName,
    CertificateApprovalIdentificationRejected: 'CertificateApproval_IdentificationRejected' as AnnouncementAnnouncementName,
    CertificateApprovalIncomeCertificateApproved: 'CertificateApproval_IncomeCertificateApproved' as AnnouncementAnnouncementName,
    CertificateApprovalIncomeCertificateRejected: 'CertificateApproval_IncomeCertificateRejected' as AnnouncementAnnouncementName,
    UserImageApprovalProfilePictureApproved: 'UserImageApproval_ProfilePictureApproved' as AnnouncementAnnouncementName,
    UserImageApprovalProfilePictureRejected: 'UserImageApproval_ProfilePictureRejected' as AnnouncementAnnouncementName,
    UserImageDeleted: 'UserImage_Deleted' as AnnouncementAnnouncementName,
    UserProfileModerated: 'UserProfile_Moderated' as AnnouncementAnnouncementName,
    UserPostBanned: 'UserPost_Banned' as AnnouncementAnnouncementName,
    ReportingActionWarning: 'ReportingAction_Warning' as AnnouncementAnnouncementName,
    IncomeCertificateRenewal: 'IncomeCertificateRenewal' as AnnouncementAnnouncementName
};

