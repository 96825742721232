import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, from } from 'rxjs';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { map, mergeMap } from 'rxjs/operators';
import { UaService } from '../ua/ua.service';
import { FingerprintService } from './fingerprint.service';

@Injectable()
export class FingerprintInterceptor implements HttpInterceptor {
  constructor(
    private uaService: UaService,
    private fingerprintService: FingerprintService,
  ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const setHeaders: any = { 'UaId': this.uaService.uaId };
    const res = this.fingerprintService.result;
    if (res?.visitorId) {
      setHeaders['VisitorId'] = res.visitorId;
    }
    return next.handle(request.clone({ setHeaders }));
  }
}
