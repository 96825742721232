import { Injectable } from '@angular/core';
import FingerprintJS, { GetResult } from '@fingerprintjs/fingerprintjs';
import { Observable, from, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FingerprintService {

  private _result: GetResult;

  constructor() { }

  load(): Observable<GetResult> {
    return from(FingerprintJS.load()).pipe(
      mergeMap(agent => agent.get()),
      map(res => {
        console.log('FingerprintService.load():', res);
        this._result = res;
        return res;
      }),
      catchError(err => {
        console.log('FingerprintService.load(): err:', err);
        return of(null);
      })
    );
  }

  get result(): GetResult { return this._result; }
}
