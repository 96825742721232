/**
 * OpenAPI common
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type UserProfileValidity = 'Empty' | 'Incomplete' | 'Valid';

export const UserProfileValidity = {
    Empty: 'Empty' as UserProfileValidity,
    Incomplete: 'Incomplete' as UserProfileValidity,
    Valid: 'Valid' as UserProfileValidity
};

